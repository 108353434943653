<template>
    <div>
        <div class="card-year-wrapper" v-if="currentItems=='年卡'">
            <div class="card-head-wrapper">
                <img src="https://seetop-1257860468.file.myqcloud.com/html/img/logo.png">
                <img src="https://seetop-1257860468.file.myqcloud.com/html/img/card_title.png">
            </div>
            <div class="card-tab-wrapper">
                <div>
                    <span class="card-tab-active">年卡</span>
                </div>
                <div @click="switchTab('月卡')">
                    <span>月卡</span>
                </div>
            </div>
            <div class="rights-wrapper">
                <img class="card-img" src="https://seetop-1257860468.file.myqcloud.com/html/img/card_year3.png">
                <div class="save-money">
                    <p>一张最多可以为你省下</p>
                    <p><span>12038元</span>的城市美食卡</p>
                </div>
                <div class="card-buy-wrapper">
                    <div class="card-buy-button" @click="buy('年卡','购卡')">
                        <p><span>￥</span>68</p>
                        <p>年卡购买</p>
                    </div>
                    <div class="card-buy-button" @click="buy('年卡','续卡')">
                        <p><span>￥</span>68</p>
                        <p>碗卡续卡</p>
                    </div>
                </div>
                <img class="rights-title" src="https://seetop-1257860468.file.myqcloud.com/vue/img/20210412%E5%B9%B4%E5%8D%A1%E6%9D%83%E7%9B%8A3.png">
                <img class="rights-content" src="https://seetop-1257860468.file.myqcloud.com/vue/img/20210412%E5%B9%B4%E5%8D%A1%E6%9D%83%E7%9B%8A1.png">
                <img class="rights-content" src="https://seetop-1257860468.file.myqcloud.com/vue/img/20210412%E5%B9%B4%E5%8D%A1%E6%9D%83%E7%9B%8A2.png">
            </div>
        </div>
        <div class="card-month-wrapper" v-else-if="currentItems=='月卡'">
            <div class="card-head-wrapper">
                <img src="https://seetop-1257860468.file.myqcloud.com/html/img/logo.png">
                <img src="https://seetop-1257860468.file.myqcloud.com/html/img/card_title.png">
            </div>
            <div class="card-tab-wrapper">
                <div @click="switchTab('年卡')">
                    <span>年卡</span>
                </div>
                <div>
                    <span class="card-tab-active">月卡</span>
                </div>
            </div>
            <div class="rights-wrapper">
                <img class="card-img" src="https://seetop-1257860468.file.myqcloud.com/html/img/card_c_m1.png">
                <div class="save-money">
                    <p>一张最多可以为你省下</p>
                    <p><span style="color: #fa1f1f">12038元</span>的城市美食卡</p>
                </div>
                <div class="card-buy-wrapper">
                    <div class="card-buy-button card-month-buy-button" @click="buy('月卡','购卡')">
                        <p><span>￥</span>9.9</p>
                        <p>月卡购买</p>
                    </div>
                    <div class="card-buy-button card-month-buy-button" @click="buy('月卡','续卡')">
                        <p><span>￥</span>9.9</p>
                        <p>月卡续卡</p>
                    </div>
                </div>
                <img class="rights-title" src="https://seetop-1257860468.file.myqcloud.com/vue/img/20210412%E6%9C%88%E5%8D%A1%E6%9D%83%E7%9B%8A3.png">
                <img class="rights-content" src="https://seetop-1257860468.file.myqcloud.com/vue/img/20210412%E6%9C%88%E5%8D%A1%E6%9D%83%E7%9B%8A1.png">
                <img class="rights-content" src="https://seetop-1257860468.file.myqcloud.com/vue/img/20210412%E6%9C%88%E5%8D%A1%E6%9D%83%E7%9B%8A2.png">
            </div>
        </div>
        <nav-bar></nav-bar>
    </div>
</template>

<script>
  import NavBar from '../../components/common/NavBar'
  // import { appendNowBuy } from '../../utils/cart'
  import { globalConfig } from '../../utils/mixin'
  export default {
    name: 'Card',
    mixins: [globalConfig],
    data () {
      return {
        currentItems: '年卡',
        isBind: 0,
        cardType: ''
      }
    },
    components: { NavBar },
    methods: {
      switchTab: function (tab) {
        this.currentItems = tab
      },
      buy: function (cardType, buyType) {
        // const that = this
        // if (!this.isBind) {
        //   this.$toast({
        //     message: '请先前往绑定',
        //     onClose: function () {
        //       that.$router.push('/bind')
        //     }
        //   })
        //   return
        // }
        // if (cardType === '年卡' && buyType === '购卡') {
        //   appendNowBuy(2, 1, 1)
        // } else if (cardType === '年卡' && buyType === '续卡') {
        //   if (that.cardType === '1') {
        //     appendNowBuy(2, 2, 1)
        //   } else {
        //     that.$toast({
        //       message: '您非碗卡会员，无法直接续费碗卡'
        //     })
        //     return
        //   }
        // } else if (cardType === '月卡' && buyType === '购卡') {
        //   appendNowBuy(2, 758, 1)
        // } else if (cardType === '月卡' && buyType === '续卡') {
        //   if (that.cardType === '2') {
        //     appendNowBuy(2, 759, 1)
        //   } else {
        //     that.$toast({
        //       message: '您非月卡会员，无法直接续费碗卡'
        //     })
        //     return
        //   }
        // }
        // // window.location.href = 'https://club.seetop.net.cn/web/pay2.html?express=0'
        // that.$router.push('/goodsOrder')
      },
      init: function () {
        const that = this
        this.axios.post(this.apiUrl + 'mall/init/card').then((response) => {
          const data = response.data
          console.log(data)
          that.isBind = data.isBindUser
          that.cardType = data.info.type
        })
      }
    },
    mounted () {
      console.log('激活')
      this.init()
    }
  }
</script>

<style scoped>
    .card-year-wrapper{
        width: 100vw;
        background: url("https://seetop-1257860468.file.myqcloud.com/html/img/bg_c_y1.png") no-repeat;
        background-size: 100vw;
    }
    .card-month-wrapper {
        width: 100vw;
        background: url("https://seetop-1257860468.file.myqcloud.com/html/img/bg_c_m1.png") no-repeat;
        background-size: 100vw;
    }
    .card-head-wrapper img:nth-child(1){
        width: 24vw;
        margin: 4vw 0 0 5vw;
    }
    .card-head-wrapper img:nth-child(2){
        width: 88vw;
        margin: 2vw 6vw;
    }
    .card-tab-wrapper{
        padding: 0 10vw;
        display: flex;
        display: -webkit-flex;
        align-items: center;
    }
    .card-tab-wrapper div{
        width:50%;
        text-align:center;
        padding:0.5rem;
        font-size:4.2vw;
        color:#fff
    }
    .card-tab-wrapper div span{
        padding:0.2rem 0.3rem
    }
    .card-img {
        width: 88vw;
        margin:0 6vw;
    }
    .save-money {
        margin: 1rem 0;
    }
    .save-money p:first-child{
        width: 100vw;
        text-align: center;
        font-size: 4vw;
        color: #999;
        margin-top: -3vw;
    }
    .save-money p:nth-child(2){
        width: 100vw;
        text-align: center;
        font-size: 4.4vw;
        font-weight: 600;
        color: #999;
    }
    .save-money p:nth-child(2) span{
        color: #333;
    }
    .card-buy-wrapper{
        width: 92vw;
        margin:  0 4vw;
        display: flex;
        justify-content: space-around;
    }
    .card-buy-button{
        width: 42vw;
        height: 15vw;
        background: url("https://seetop-1257860468.file.myqcloud.com/html/img/button_c_y1.png") no-repeat;
        background-size: 100% 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 4.2vw;
    }
    .card-buy-button p{
        margin-top: -1.2vw;
        font-size: 3.8vw;
    }
    .card-buy-button p:nth-child(1){
        margin-left: 3.8vw;
        color: #D4D4D4;
    }
    .card-buy-button p:nth-child(2){
        margin-right: 5vw;
        font-weight: 600;
        color: #242424;
    }
    .card-buy-button span{
        font-size: 2.8vw;
    }
    .card-month-buy-button{
        width: 42vw;
        height: 15vw;
        background: url("https://seetop-1257860468.file.myqcloud.com/html/img/button_c_m1.png") no-repeat;
        background-size: 100% 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 4.2vw;
    }
    .card-month-buy-button p:nth-child(1){
        margin-left: 3.8vw;
        color: #fff;
    }
    .card-month-buy-button p:nth-child(2){
        margin-right: 5vw;
        font-weight: 600;
        color: #DF131D;
    }
    .rights-wrapper {}
    .rights-title {
        display: block;
        width: 70%;
        margin: 2rem auto;
    }
    .rights-content {
        display: block;
        width: 93%;
        margin: 0 auto;
    }
    .card-tab-active{
        font-size: 4.5vw;
        border-bottom: 0.18rem solid #fff;
    }
</style>
